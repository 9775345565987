export const mobile = () => {
    function Android() {
        return navigator.userAgent.match(/Android/i);
    }

    function BlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
    }

    function iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    function Opera() {
        return navigator.userAgent.match(/Opera Mini/i);
    }

    function IE() {
        return navigator.userAgent.match(/IEMobile/i);
    }

    return (Android() || BlackBerry() || iOS() || Opera() || IE() || navigator.userAgent.match(/Windows Phone/i))
}