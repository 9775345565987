import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "../index"
import ProxyFactory from "@/apiProxies/proxyFactory";
import { PaymentDocument, PaymentDocumentFileType } from '@/apiProxies/dataContracts/personalAccount'
import { FileDescription } from "@/apiProxies/dataContracts";
import { isEmpty } from "lodash";

@Module({ store, name: 'paymentDocumentsModule', namespaced: true, dynamic: true, stateFactory: true })
export class PaymentDocuments extends VuexModule {

    private _paymentDocumentsBeginDate: Date = new Date(new Date().getFullYear(), 0, 1)
    private _paymentDocumentsEndDate: Date = new Date(new Date().getFullYear(), 11, 31)
    private _paymentDocuments: Array<PaymentDocument> = []

    public get paymentDocuments(): PaymentDocument[] | undefined {
        return this._paymentDocuments;
    }

    public get paymentDocumentsBeginDate(): Date {
        return this._paymentDocumentsBeginDate;
    }

    public get paymentDocumentsEndDate(): Date {
        return this._paymentDocumentsEndDate;
    }

    @Mutation
    setPaymentDocuments(contracts: Array<PaymentDocument>): void {
        this._paymentDocuments = contracts
    }

    @Mutation
    setPaymentDocumentsDates(value: { beginDate: Date, endDate: Date }): void {
        this._paymentDocumentsBeginDate = value.beginDate
        this._paymentDocumentsEndDate = value.endDate
    }

    @Action
    public async loadPaymentDocuments(contractId: string): Promise<void> {
        const proxy = await ProxyFactory.createPaymentDocumentsPresenterProxy()

        const privateContracts = await proxy.getPaymentDocuments(contractId, this.paymentDocumentsBeginDate, this.paymentDocumentsEndDate)

        this.setPaymentDocuments(privateContracts)
    }

    @Action
    public async getPaymentDocumentFileInfo(body: { contractId: string, period: string, fileType: PaymentDocumentFileType }): Promise<FileDescription | undefined> {
        const proxy = await ProxyFactory.createPaymentDocumentsPresenterProxy()

        const result = await proxy.getPaymentDocumentFileInfo(body.contractId, body.period, body.fileType)

        return isEmpty(result) ? undefined : result
    }

    @Action
    public async downloadPaymentDocumentFile(body: { contractId: string, period: string, fileType: PaymentDocumentFileType }): Promise<Blob> {
        const proxy = await ProxyFactory.createPaymentDocumentsPresenterProxy()

        return await proxy.downloadPaymentDocumentFile(body.contractId, body.period, body.fileType)
    }
}

export const paymentDocumentsModule = getModule(PaymentDocuments);