<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1153_65935)">
      <path
        d="M35.837 7.77319C36.782 8.9935 37.5894 10.3263 38.234 11.7491L20.0095 25.1791L12.3962 20.4064V14.6649L20.0095 19.4375L35.837 7.77319Z"
        fill="#21A038"></path>
      <path
        d="M4.62909 19.9998C4.62909 19.7417 4.6357 19.4863 4.64894 19.2309L0.0349141 19.0005C0.0190311 19.3314 0.00976562 19.665 0.00976562 19.9998C0.00976562 25.523 2.24795 30.5233 5.86797 34.1418L9.13987 30.8701C6.35372 28.0853 4.62909 24.2404 4.62909 19.9998Z"
        fill="url(#paint0_linear_1153_65935)"></path>
      <path
        d="M20.008 4.62048C20.2661 4.62048 20.5215 4.62975 20.777 4.64166L21.0073 0.0251476C20.6764 0.00926511 20.3429 0 20.008 0C14.4847 0 9.48416 2.2381 5.86548 5.85799L9.13738 9.12978C11.9235 6.34505 15.7685 4.62048 20.008 4.62048Z"
        fill="url(#paint1_linear_1153_65935)"></path>
      <path
        d="M20.0076 35.3809C19.7495 35.3809 19.4941 35.3742 19.2386 35.361L19.0083 39.9749C19.3392 39.9907 19.6727 40 20.0076 40C25.5309 40 30.5314 37.7619 34.1501 34.142L30.8782 30.8702C28.0934 33.655 24.2484 35.3809 20.0076 35.3809Z"
        fill="url(#paint2_linear_1153_65935)"></path>
      <path
        d="M28.6806 7.30461L32.5706 4.43783C29.1372 1.66369 24.7681 0 20.0098 0V4.62048C23.2234 4.62048 26.2094 5.61181 28.6806 7.30461Z"
        fill="url(#paint3_linear_1153_65935)"></path>
      <path
        d="M40.0096 19.9999C40.0096 18.777 39.8997 17.5792 39.6893 16.4171L35.3836 19.5896C35.3876 19.726 35.3889 19.8623 35.3889 19.9999C35.3889 24.5159 33.4327 28.5831 30.3223 31.3996L33.4261 34.8302C37.4696 31.1719 40.0096 25.8831 40.0096 19.9999Z"
        fill="#21A038"></path>
      <path
        d="M20.0075 35.3808C15.4915 35.3808 11.4241 33.4246 8.6075 30.3143L5.17676 33.418C8.83647 37.4601 14.1255 39.9999 20.0075 39.9999V35.3808Z"
        fill="url(#paint4_linear_1153_65935)"></path>
      <path
        d="M9.69577 8.60029L6.59196 5.16968C2.54972 8.82926 0.00976562 14.1181 0.00976562 19.9999H4.63041C4.62909 15.484 6.58534 11.4168 9.69577 8.60029Z"
        fill="url(#paint5_linear_1153_65935)"></path>
    </g>
    <defs>
      <linearGradient id="paint0_linear_1153_65935" x1="6.80191" y1="33.5642" x2="1.75376" y2="18.9716"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.1444" stop-color="#F2E913"></stop>
        <stop offset="0.3037" stop-color="#E7E518"></stop>
        <stop offset="0.5823" stop-color="#CADB26"></stop>
        <stop offset="0.891" stop-color="#A3CD39"></stop>
      </linearGradient>
      <linearGradient id="paint1_linear_1153_65935" x1="7.18546" y1="6.67627" x2="20.1881" y2="1.72106"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.0592" stop-color="#0FA8E0"></stop>
        <stop offset="0.5385" stop-color="#0099F9"></stop>
        <stop offset="0.9234" stop-color="#0291EB"></stop>
      </linearGradient>
      <linearGradient id="paint2_linear_1153_65935" x1="18.704" y1="37.2131" x2="33.4078" y2="33.8898"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.1226" stop-color="#A3CD39"></stop>
        <stop offset="0.2846" stop-color="#86C339"></stop>
        <stop offset="0.8693" stop-color="#21A038"></stop>
      </linearGradient>
      <linearGradient id="paint3_linear_1153_65935" x1="19.042" y1="1.54891" x2="31.6134" y2="5.42788"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.0566" stop-color="#0291EB"></stop>
        <stop offset="0.79" stop-color="#0C8ACB"></stop>
      </linearGradient>
      <linearGradient id="paint4_linear_1153_65935" x1="6.33549" y1="32.8564" x2="20.0195" y2="37.9694"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.1324" stop-color="#F2E913"></stop>
        <stop offset="0.2977" stop-color="#EBE716"></stop>
        <stop offset="0.5306" stop-color="#D9E01F"></stop>
        <stop offset="0.8023" stop-color="#BBD62D"></stop>
        <stop offset="0.9829" stop-color="#A3CD39"></stop>
      </linearGradient>
      <linearGradient id="paint5_linear_1153_65935" x1="1.72977" y1="20.5604" x2="7.22234" y2="6.27165"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.0698" stop-color="#A3CD39"></stop>
        <stop offset="0.2599" stop-color="#81C55F"></stop>
        <stop offset="0.9216" stop-color="#0FA8E0"></stop>
      </linearGradient>
      <clipPath id="clip0_1153_65935">
        <rect width="40" height="40" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>