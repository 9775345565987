<template>
    <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308 378.66666" height="378.66666" width="308" xml:space="preserve"
        id="svg2" version="1.1">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,378.66667)" id="g10">
            <g transform="scale(0.1)" id="g12">
                <path id="path14" style="fill:#62b22f;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 979.027,1278.3 1326.15,1050.07 1326.13,626.648 977.426,2.14844 979.027,1278.3" />
                <path id="path16" style="fill:#974995;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 691.176,1458.6 1037.52,1257.9 9.32813,633.449 344.926,1244.25 691.176,1458.6" />
                <path id="path18" style="fill:#ee7024;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="m 2306.23,2035.65 -661.7,3.3 -318.7,191.6 -346.4,606.2 1326.8,-801.1" />
                <path id="path20" style="fill:#fbb61b;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="m 979.43,2836.75 346.4,-606.2 0.7,-835.31 -349.202,106.71 2.102,1334.8" />
                <path id="path22" style="fill:#5a58a3;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 6.52734,2226.85 346.027,1609.65 344.926,1244.25 9.32813,633.449 6.52734,2226.85" />
                <path id="path24" style="fill:#2188cb;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 6.52734,2226.85 2301.03,822.949 1643.53,818.348 346.027,1609.65 6.52734,2226.85" />
                <path id="path26" style="fill:#da0954;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="m 1644.53,2038.95 661.7,-3.3 -979.4,-602.1 -1,411.2 318.7,194.2" />
                <path id="path28" style="fill:#037f37;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="m 1326.13,626.648 317.4,191.7 657.5,4.601 L 977.426,2.14844 1326.13,626.648" />
            </g>
        </g>
    </svg>
</template>

<script setup></script>