<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt"
    viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet" class="payment-icon">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M495 4056 c-93 -29 -152 -77 -196 -158 l-34 -63 0 -1275 0 -1275 27 -52 c37 -70 89 -120 157 -152 l56 -26 2055 0 2055 0 56 26 c68 32 120 82 157 152 l27 52 0 1275 0 1275 -27 52 c-37 70 -89 120 -157 152 l-56 26 -2040 2 c-1621 1 -2048 -1 -2080 -11z m4140 -108 c45 -24 85 -68 106 -113 16 -34 19 -67 19 -227 l0 -188 -2200 0 -2200 0 0 188 c0 164 3 192 20 227 20 43 33 59 67 88 56 48 -34 46 2128 44 1869 -2 2032 -3 2060 -19z m125 -888 l0 -260 -2200 0 -2200 0 0 260 0 260 2200 0 2200 0 0 -260z m0 -1047 c0 -646 -2 -690 -19 -728 -21 -45 -61 -89 -106 -113 -28 -16 -190 -17 -2075 -17 -1885 0 -2047 1 -2075 17 -45 24 -85 68 -106 113 -17 38 -19 82 -19 728 l0 687 2200 0 2200 0 0 -687z">
      </path>
      <path
        d="M838 2161 l-33 -29 -3 -234 c-4 -253 2 -294 45 -327 25 -20 37 -21 449 -21 l424 0 32 29 33 29 3 246 c3 269 -2 301 -54 325 -17 8 -152 11 -444 11 l-420 0 -32 -29z m852 -291 l0 -220 -395 0 -395 0 0 220 0 220 395 0 395 0 0 -220z">
      </path>
      <path
        d="M2550 2171 c-15 -28 -12 -46 8 -64 17 -16 95 -17 911 -17 596 0 899 3 912 10 22 12 26 59 7 78 -9 9 -227 12 -920 12 -887 0 -908 0 -918 -19z">
      </path>
      <path
        d="M2556 1684 c-9 -8 -16 -24 -16 -34 0 -42 24 -50 155 -50 131 0 155 8 155 50 0 42 -24 50 -155 50 -99 0 -127 -3 -139 -16z">
      </path>
      <path
        d="M3076 1684 c-19 -18 -21 -55 -4 -72 8 -8 54 -12 139 -12 103 0 131 3 143 16 21 21 20 47 -2 67 -26 24 -253 25 -276 1z">
      </path>
      <path
        d="M3598 1689 c-21 -12 -24 -59 -6 -77 8 -8 54 -12 140 -12 125 0 128 0 144 25 15 23 15 27 0 50 -16 24 -19 25 -139 25 -67 0 -130 -5 -139 -11z">
      </path>
      <path
        d="M4112 1688 c-7 -7 -12 -24 -12 -38 0 -45 15 -50 150 -50 135 0 150 5 150 50 0 45 -15 50 -150 50 -85 0 -130 -4 -138 -12z">
      </path>
    </g>
  </svg>
</template>