export const parseCurrency = (value: number, count = 2): string => {
    if (value === 0) return '0'

    return value.toFixed(count)
}

export const parseCurrencySign = (value: number, sign: boolean, count = 2): string => {
    if (value === 0) return '0'

    if (!sign) {
        value *= -1
    }

    return value.toFixed(count)
}